<template>
  <div class="row">
    <div class="col-12 col-lg-6 c g">
        <img :src="require('@/assets/images/feedback.jpg')" style="width: 100%; border-radius: 10px 10px 0px 0px">
        <div class="card card-body" style="border-radius: 0px 0px 10px 10px">
            <div class="row" v-if="type == ''">
                <div class="col-12 col-lg-4 text-center" @click="type = 'ticket'">
                    <div class="card card-body border text-center ic">
                        <img :src="require('@/assets/images/mechanic.png')" style="width: 80px; margin: 0px auto;">
                        <br>
                        ابلاغ عن مشكل<br> في النظام
                    </div>
                </div>
                <div class="col-12 col-lg-4 text-center" @click="type = 'feedback';">
                    <div class="card card-body border text-center ic">
                        <img :src="require('@/assets/images/review.png')" style="width: 80px; margin: 0px auto;">
                        <br>
                        رأيك واقتراحك<br>لتطوير النظام
                    </div>
                </div>
                <div class="col-12 col-lg-4 text-center" @click="type = '3'">
                    <div class="card card-body border text-center ic">
                        <img :src="require('@/assets/images/phone-call.png')" style="width: 80px; margin: 0px auto;">
                        <br>
                        حجز موعد اتصال<br>عبر الجوال
                    </div>
                </div>
                <div class="col-12 g text-center">
                    يمكنك التواصل معنا عبر الواتساب على:
 <i class="fa fa-whatsapp"></i> 966580200670
                </div>
            </div>
            <template v-if="type == '3'">
                <div class="form-group">
                  <h5 for="">سبب الاتصال</h5>
                  <textarea class="form-control form-control-lg" v-model="reason" rows="3" placeholder="برجاء توضيح سبب الاتصال. مثل: استفسار عن كذا، مشكلة كذا"></textarea>
                </div>
                <div class="form-group">
                  <h5 for="">رقم الجوال المراد الاتصال به</h5>
                  <input type="text"
                    class="form-control form-control-lg" v-model="phone" placeholder="اكتب رقم الجوال المراد الاتصال عليه">
                </div>
                <strong>
                    <h5>اختر موعد الاتصال المناسب لك:</h5>
                </strong>
                <div class="form-group" v-if="reason == 'lolo'">
                  <label for="">موعد اتصال مخصص</label>
                  <input type="datetime-local"
                    class="form-control" v-model="selected_time_custom" @change="selected_time_customx()">
                </div>
                <div class="alert alert-warning g" v-if="times.length == 0">
                    لا يوجد مواعيد متاحة للاتصال، برجاء التواصل معنا عبر الواتس اب.
                </div>
                <template v-if="reason != 'lolo'">
                    <div v-for="t in times" :key="t.date" class="form-check">
                        <label class="form-check-label">
                        <input type="radio" class="form-check-input" v-model="selected_time" :value="t">
                        {{ t.title }}
                    </label>
                    </div>
                </template>
                <div class="col-12 text-center g">
                    <button class="btn btn-primary" @click="registerTime()">
                        حجز الموعد الآن
                    </button>
                </div>
            </template>
            <template v-if="type == 'ticket' || type == 'feedback'">
                <div class="form-group">
                <h5 for="">الخدمة</h5>
                <select class="form-control" v-model="service">
                    <option value="النظام">النظام بشكل عام</option>
                    <option v-for="s in services" :key="s.title" :value="s.title">
                        {{ s.title }}
                    </option>
                </select>
                </div>
                <div class="form-group">
                <h5 for="">{{ type == 'ticket' ? 'المشكلة' : 'رأيك / اقتراحك بالتفصيل' }}</h5>
                <textarea class="form-control" v-model="title" placeholder="اكتب هنا..." rows="3"></textarea>
                </div>
                <div class="form-group" v-if="type == 'feedback'">
                <h5 for="">ما هو تقييمك للنظام الحالي؟</h5>
                <select class="form-control" v-model="rate">
                    <option value="سيئ">سيئ</option>
                    <option value="يحتاج بعض التحسينات">يحتاج بعض التحسينات</option>
                    <option value="جيد">جيد</option>
                    <option value="ممتاز">ممتاز</option>
                </select>
                </div>
                <div class="form-group">
                <span for="">ارفاق ملف / صورة توضيحية (اختياري)</span>
                <input type="file"
                    class="form-control" id="ingredient_file" @change="uploadImage()" accept=".jpg,.png,.gif,.jpeg,.doc,.docx,.pdf,.xls,.xlsx">
                </div>
                <div class="alert alert-info g text-center" v-if="uploading">
                    جاري الرفع...
                </div>
                <div class="col-12 text-center g" v-if="!uploading">
                    <button class="btn btn-success" v-if="!sending" @click="send()">
                        ارسال الآن <i class="fa fa-arrow-left"></i>
                    </button>
                    <button class="btn btn-success" v-if="sending">
                        جاري الارسال...
                    </button>
                </div>
            </template>
        </div>
    </div>
    <div class="col-12 c" v-if="mytimes.length">
        <div class="card card-body">
            <h5>مواعيد الاتصال</h5>
            <div class="col-12 table-responsive" style="min-height: auto !important;">
                <table class="table table-hover table-striped table-striped table-sm">
                    <thead>
                        <th>
                            الحالة
                        </th>
                        <th>
                            موعد الاتصال
                        </th>
                        <th>
                            السبب
                        </th>
                        <th>
                            الرقم
                        </th>
                        <th>
                            خيارات
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="time in mytimes" :key="time._id">
                            <td>
                                <span v-if="time.status == 0" class="btn btn-sm btn-warning">
                                    في الانتظار
                                </span>
                                <span v-if="time.status == 1" class="btn btn-sm btn-success">
                                    تم الاتصال
                                </span>
                                <span v-if="time.status == 2" class="btn btn-sm btn-danger">
                                    ملغي
                                </span>
                            </td>
                            <td>
                                {{ time.date }}
                            </td>
                            <td>
                                {{ time.reason }}
                            </td>
                            <td>
                                {{ time.phone }}
                            </td>
                            <td>
                               <button class="btn btn-outline-danger btn-sm" @click="cancel(time._id)" v-if="time.status == 0">
                                    <i class="fa fa-times"></i>
                                    الغاء الموعد
                               </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="col-12 c" v-if="tickets.length">
        <div class="card card-body">
            <h5>المشاكل / التذاكر</h5>
            <div class="col-12 table-responsive" style="min-height: auto !important;">
                <table class="table table-hover table-striped table-striped table-sm">
                    <thead>
                        <th>
                            التاريخ
                        </th>
                        <th>
                            الخدمة
                        </th>
                        <th>
                            العنوان
                        </th>
                        <th>
                            الحالة
                        </th>
                        <th>
                            فتح المناقشة
                        </th>
                    </thead>
                    <tbody>
                        <template v-for="feedback in tickets">
                            <tr v-if="feedback.type == 'ticket'" :key="feedback._id" :class="feedback.unread > 0 ? 'bg-light-danger' : ''">
                                <td>
                                    {{ feedback.date }}
                                </td>
                                <td>
                                    {{ feedback.service }}
                                </td>
                                <td @click="$router.push('/ticket/' + feedback._id)">
                                    {{ feedback.title.substring(0, 30) }}
                                </td>
                                <td>
                                    <span class="btn btn-sm btn-success" v-if="feedback.seen"><i class="fa fa-check-square"></i> تم غلق التذكرة</span>
                                    <span class="btn btn-sm btn-info" v-if="!feedback.seen"><i class="fa fa-clock-o"></i> مفتوحة</span>
                                </td>
                                <td>
                                    <button class="btn btn-success" @click="$router.push('/ticket/' + feedback._id)">
                                        <i class="fa fa-comment"></i>
                                        فتح المناقشة 
                                        <template v-if="feedback.unread > 0">
                                            <span>(<i class="fa fa-bell"></i> {{ feedback.unread }})</span>
                                        </template>
                                        <i class="fa fa-arrow-left"></i>
                                    </button>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="col-12 c" v-if="tickets.length">
        <div class="card card-body">
            <h5>الاراء والاقتراحات</h5>
            <div class="col-12 table-responsive" style="min-height: auto !important;">
                <table class="table table-hover table-striped table-striped table-sm">
                    <thead>
                        <th>
                            التاريخ
                        </th>
                        <th>
                            الخدمة
                        </th>
                        <th>
                            التقييم
                        </th>
                        <th>
                            المحتوى
                        </th>
                        <th>
                            الحالة
                        </th>
                        <th>
                            فتح المناقشة
                        </th>
                    </thead>
                    <tbody>
                        <template v-for="feedback in tickets">
                            <tr v-if="feedback.type == 'feedback'" :key="feedback._id" :class="feedback.unread > 0 ? 'bg-light-danger' : ''">
                                <td>
                                    {{ feedback.date }}
                                </td>
                                <td>
                                    {{ feedback.service }}
                                </td>
                                <td>
                                    {{ feedback.rate }}
                                </td>
                                <td @click="$router.push('/ticket/' + feedback._id)">
                                    {{ feedback.title.substring(0, 30) }}
                                </td>
                                <td>
                                    <span class="btn btn-sm btn-success" v-if="feedback.seen"><i class="fa fa-check-square"></i> تم غلق التذكرة</span>
                                    <span class="btn btn-sm btn-info" v-if="!feedback.seen"><i class="fa fa-check"></i> تم الارسال</span>
                                </td>
                                <td>
                                    <button class="btn btn-success" @click="$router.push('/ticket/' + feedback._id)">
                                        <i class="fa fa-comment"></i>
                                        فتح المناقشة 
                                        <template v-if="feedback.unread > 0">
                                            <span>(<i class="fa fa-bell"></i> {{ feedback.unread }})</span>
                                        </template>
                                        <i class="fa fa-arrow-left"></i>
                                    </button>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            service: "النظام",
            selected_time: null,
            services: [],
            rate: "جيد",
            content: "",
            sending: false,
            selected_time_custom: "",
            reason: "",
            uploading: false,
            phone: "",
            mytimes: [],
            times: [],
            file: "",
            title: "",
            type: "",
            tickets: []
        }
    },
    created(){
        var g = this;
        this.loading = true
        g.phone = g.user.phone
        $.post(api + '/general/services', {
            jwt: this.user.jwt
        }).then(function(r){
            if(r.status != 100){
                alert(r.response)
            }else{
                g.services = r.response
            }
        }).fail(function(){
            g.loading = false
        })
        g.getFeedbacks()
        g.cc()
    },
    methods: {

        uploadImage(){
            var g = this;
            var Upload = function (file) {
                this.file = file;
            };

            Upload.prototype.getType = function () {
                return this.file.type;
            };
            Upload.prototype.getSize = function () {
                return this.file.size;
            };
            Upload.prototype.getName = function () {
                return this.file.name;
            };
            Upload.prototype.doUpload = function () {
                var that = this;
                var formData = new FormData();
                g.uploading = true;
                // add assoc key values, this will be posts values
                formData.append("file", this.file, this.getName());
                formData.append("jwt", g.user.jwt);

                $.ajax({
                type: "POST",
                url: api + '/user/general/upload',
                xhr: function () {
                    var myXhr = $.ajaxSettings.xhr();
                    if (myXhr.upload) {
                    myXhr.upload.addEventListener(
                        "progress",
                        that.progressHandling,
                        false
                    );
                    }
                    return myXhr;
                },
                success: function (data) {
                    // your callback here
                    g.uploading = false;
                    if(data.status != 100){
                        alert("هذا الملف غير مدعوم", 200)
                        return;
                    }
                    g.file = data.response
                },
                error: function (error) {
                    // handle error
                    alert("حدث خطأ");
                    g.uploading = false;
                },
                async: true,
                data: formData,
                cache: false,
                contentType: false,
                processData: false,
                timeout: 60000,
                });
            };
            var file = $("#ingredient_file")[0].files[0];
            var upload = new Upload(file);
            upload.doUpload();
        },
        cancel(id){
            var g = this;
            if(confirm("متأكد من الغاء موعد الاتصال؟")){
                $.post(api + '/user/general/cancel-time', {
                    jwt: this.user.jwt,
                    id: id
                }).then(function(r){
                    if(r.status != 100){
                        alert(r.response, 200)
                    }else{
                        alert("تم الغاء الموعد بنجاح", 100)
                        g.cc()
                    }
                }).fail(function(){
                    g.loading = false
                })
            }
        },
        selected_time_customx(){
            var g = this;
            g.selected_time = {
                title: g.selected_time_custom.replace("T", " "),
                date: g.selected_time_custom.replace("T", " ")
            }
        },
        cc(){
            var g = this;
            $.post(api + '/user/general/times', {
                jwt: this.user.jwt
            }).then(function(r){
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.times = r.response
                    if(r.response[0]){
                        g.selected_time = r.response[0]
                    }
                }
            }).fail(function(){
                g.loading = false
            })
            $.post(api + '/user/general/my-registers-times', {
                jwt: this.user.jwt
            }).then(function(r){
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.mytimes = r.response
                }
            }).fail(function(){
                g.loading = false
            })
        },
        send(){
            var g = this;
            g.sending = true;
            $.post(api + '/user/general/feedback', {
                jwt: this.user.jwt,
                service: this.service,
                title: this.title,
                rate: this.rate,
                file: this.file,
                type: this.type
            }).then(function(r){
                g.sending = false;
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    g.getFeedbacks();
                    alert("تم الارسال لادارة النظام بنجاح!", 100)
                    g.$router.push(`/ticket/${r.response}`)
                }
            }).fail(function(){
                g.sending = false;
                alert('حدث خطأ في الاتصال', 200)
            })
        },
        getFeedbacks(){
            var g = this;
            $.post(api + '/user/general/tickets', {
                jwt: this.user.jwt
            }).then(function(r){
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.tickets = r.response
                }
            }).fail(function(){
                g.loading = false
            })
        },
        registerTime(){
            var g = this;
            if(!g.selected_time){
                return alert("قم باختيار موعد", 200);
            }
            $.post(api + '/user/general/register-time', {
                jwt: this.user.jwt,
                date: this.selected_time.date,
                title: this.selected_time.title,
                phone: this.phone,
                reason: this.reason
            }).then(function(r){
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    alert("تم حجز الموعد بنجاح", 100)
                    g.cc()
                }
            }).fail(function(){
                g.loading = false
            })
        }
    }
}
</script>

<style>
.ic:hover{
    background: #eee;
    cursor: pointer;
}
.ic{
    background: #fafafa;
}
</style>